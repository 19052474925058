import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import estimationArticleImg from '../assets/estimation-article-img.jpg';

const EstimationArticle = () => {
    const { t } = useTranslation();
    return <div className="article-container">
        <Container>
            <Row className="article article__fit-content article-cell__center ">

                <Col sm={6}>
                    <div className="article-cell article-cell__thin-padding-right">
                        <div className="article-cell-header">
                            <div className="title">
                                {t('article5.subText1')}
                                <div className="title-subtitle">
                                    {t('article5.mainText1')}
                                    <div className="title-lightblue">
                                        {t('article5.mainText2')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img className="estimation-img__article" src={estimationArticleImg} alt="acoompagnement agent" />
                    </div>
                </Col>
                <Col sm={6}>
                    <div className="article-cell">
                        <div className="faq">
                            <p className='faq-question'>
                                Q : {t('article5.FQA.0.Q')}
                            </p>
                            <p className='faq-answer'>
                                A : {t('article5.FQA.0.A')}
                            </p>
                        </div>
                        <div className="faq">
                            <p className='faq-question'>
                                Q : {t('article5.FQA.1.Q')}
                            </p>
                            <p className='faq-answer'>
                                A : {t('article5.FQA.1.A')}
                            </p>
                        </div>
                        <div className="faq">
                            <p className='faq-question'>
                                Q : {t('article5.FQA.2.Q')}
                            </p>
                            <p className='faq-answer'>
                                A : {t('article5.FQA.2.A')}
                            </p>
                        </div>
                        <div className="faq">
                            <p className='faq-question'>
                                Q : {t('article5.FQA.3.Q')}
                            </p>
                            <p className='faq-answer'>
                                A : {t('article5.FQA.3.A')}
                            </p>
                        </div>
                    </div>
                </Col>

            </Row>
        </Container>
    </div>
}

export default EstimationArticle;