import '../styles/header.scss';
import SearchInput from './search-input';
import Steps from './steps';
import { Form, Step } from '../interface/forms';
import HeaderInner from './header-inner';
import { useTranslation } from 'react-i18next';
interface HeaderProps {
    form: Form;
    setForm: (o: Form) => void;
    step: Step;
    showSearch?: boolean;
}

const Header = (props: HeaderProps) => {
    const { t } = useTranslation();
    const { step, showSearch, form, setForm } = props;

    return <>
        <header className="container-fluid header-img">
            <div className="header-container">
                <HeaderInner />
                <div className='header-wrapper'>
                    <div className="header-title"><div className='header-title__uppercase'>{t('subText1')}</div><div className="header-title__strong">{t('mainText1')}</div></div>
                    {step ? <Steps step={step} /> : null}
                    {showSearch ? <SearchInput form={form} setForm={setForm} /> : <></>}
                </div>
            </div>

        </header>
    </>
}

export default Header;