import React, { useState, useEffect } from 'react';
import { ProgressBar, Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import HeaderInner from './header-inner';
import '../styles/header.scss';
import euroImg from "../assets/euro.png";

import API from '../api/api';
import { Response } from '../interface/api';
import { Form } from '../interface/forms';
import { useHistory } from 'react-router';
import { STATUS } from '../utils/utils';



interface GaugeHeaderProps {
    address: string;
    form: Form;
    setForm: (o: Form) => void;
}

const GaugeHeader = (props: GaugeHeaderProps) => {
    const [progress, setProgress] = useState({ now: 0, status: "", hideAlert: false, errorMessage: null });
    const history = useHistory();
    const { t } = useTranslation();

    const sendRequest = () => {
        setProgress(previousState => ({ ...previousState, now: 0 }));
        const interval = updateProgressBar();
        const { saleForm, propertyForm, sellerInfosForm, address } = props.form;

        if (!!saleForm && !!propertyForm && !!sellerInfosForm && !!address) {
            API.postEstimation({
                propertyEstimationDetails: {
                    address: { // "Adresse du bien à estimer" converted via google apis
                        street: address?.route,
                        houseNumber: address?.street_number,
                        city: address?.locality,
                        postCode: address?.postal_code
                    },
                    hasLift: propertyForm?.hasLift, // NO FRENCH TEXT YET !== '0' ? true : false
                    hasPool: propertyForm?.hasPool, // NO FRENCH TEXT YET
                    //parkingSpaceType?: ParkingSpaceType, // NO FRENCH TEXT YET
                    propertyType: propertyForm.propertyType, // "Type de bien"
                    buildingYear: propertyForm.buildingYear, // "Année de construction". Integer number expected
                    livingArea: propertyForm.livingArea, // "Surface"
                    landArea: propertyForm.landArea, // "Surface du terrain"
                    numberOfRooms: propertyForm.numberOfRooms, // "Nb de pièce(s)", Integer number expected
                    nubmerOfBedrooms: propertyForm?.nubmerOfBedrooms, // "Nb de chambre(s)", Integer number expected
                    numberOfBathrooms: propertyForm?.numberOfBathrooms, // NO FRENCH TEXT YET, Integer number expected
                    floorNumber: propertyForm?.floor, // "Étage", Integer number expected
                    energyLevel: saleForm?.energyLevel, // "Note DPE (ou estimée)"
                    heatingType: propertyForm?.heatingType, // "Chauffage"
                    hasRecentHeavyRenovation: saleForm?.hasRecentHeavyRenovation, // "Avez-vous réalisé des travaux de gros oeuvre ?"
                    hasRecentRenovation: saleForm?.hasRecentRenovation, // "NO FRENCH TEXT YET"
                    isRenovationRequired: saleForm?.isRenovationRequired, // "Rénovation à prévoir ?"
                },
                propertyLeadDetails: {
                    enquiryReason: sellerInfosForm.enquiryReason,
                    purchaseYear: saleForm.datePurchase, // "Depuis quand êtes-vous propriétaire ?" 
                    purchasePrice: saleForm.purchasePrice, // "Prix d'achat (hos frais agence et notaire)"
                    sellingPrice: saleForm.sellingPrice, // "NO FRENCH TEXT YET"
                    relationToProperty: saleForm.relationToProperty,
                    personType: saleForm.personType,
                    firstName: sellerInfosForm.firstName,
                    lastName: sellerInfosForm.lastname,
                    phoneNumber: sellerInfosForm.phoneNumber,
                    email: sellerInfosForm.email,
                },
                smsConfirmationCode: sellerInfosForm.verCode
            }, (response: Response) => {
                clearInterval(interval);
                setProgress(previousState => ({ ...previousState, now: 100 }));
                props.setForm({ ...props.form, estimatedPrice: response.valuation, propertyPricePerEnergyLabels: response.propertyPricePerEnergyLabels });

                setTimeout(() => {
                    history.push("/estimation");
                }, 1000);
            }, (error: any) => {

                clearInterval(interval);
                return setProgress(previousState => ({ ...previousState, now: 100, status: STATUS.ERROR, errorMessage: error.errorMessage }));
            });
        }
    }

    const updateProgressBar = (): NodeJS.Timeout => {
        return setInterval(() => {
            const getRand = (num: number) => num < 85 ? num + Math.floor(Math.random() * (10 - 3) + 3) : 93;

            setProgress(previousState => ({ ...previousState, now: getRand(previousState.now) }));
        }, 1000);
    }

    useEffect(() => {
        sendRequest();
        window.scrollTo(0, 0);
    }, []);

    return <>
        <header className="container-fluid header-img">
            <div className="header-container">
                <HeaderInner />
                <div className='header-wrapper'>
                    <div>
                        <div className="header-sub-title">
                            <img src={euroImg} alt="icone euro" />
                        </div>
                        <div className="header-title header-title__short"><div className="header-title__strong">{t('step5.header.mainText1')}</div>
                            <div className='header-title__sub'>{props.address}</div>
                        </div>
                        <div className="progress-container">
                            <ProgressBar now={progress.now <= 100 ? progress.now : 100} label={<>{progress.now <= 100 ? progress.now : 100}%</>} />
                        </div>
                        {progress.status === STATUS.ERROR ?
                            <Alert dismissible key={"alert-estimation"} variant={"danger"} onClose={() => setProgress(previousState => ({ ...previousState, hideAlert: true }))} show={!progress.hideAlert}>
                                {t('step5.error')}
                                <br />
                                {progress.errorMessage}
                                <div className="btn-container-flex">
                                    <Button onClick={sendRequest} variant="sextant" size="lg">
                                        <b>{t('step5.errorBtn')}</b>
                                    </Button>
                                </div>
                            </Alert>
                            : null}
                    </div>
                </div>
            </div>
        </header>
    </>
}


export default GaugeHeader;