import { useHistory } from "react-router-dom";
import "../styles/steps.scss";

interface StepsProps {
    step: number;
}

const Steps = (props: StepsProps) => {
    const history = useHistory();
    const onClick = (path: string, nextStep: number, currentStep: number) => {
        if (nextStep <= currentStep) {
            history.push(path);
        }
    }

    return <div className="steps-container">
        <div onClick={onClick.bind(null, "/step1", 1, props.step)} className={`step-element ${props.step >= 1 ? "active" : ""}`}>
            <div>01</div>
        </div>
        <div onClick={onClick.bind(null, "/step2", 2, props.step)} className={`step-element ${props.step >= 2 ? "active" : ""}`}>
            <div>02</div>
        </div>
        <div onClick={onClick.bind(null, "/step3", 3, props.step)} className={`step-element ${props.step >= 3 ? "active" : ""}`}>
            <div>03</div>
        </div>
    </div>
}

export default Steps;