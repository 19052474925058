import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Form, Row, Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Button from "react-bootstrap/Button";
import { Form as FormType, SellerInfosFormData } from "../interface/forms";
import { useHistory } from "react-router-dom";
import { parseDigits, parsePhoneNumber } from "libphonenumber-js";
import API from "../api/api";
import { STATUS } from '../utils/utils';

interface SellerInfosFormProps {
  form: FormType;
  setForm: (o: FormType) => void;
  label: string;
  contact: boolean;
}

const SellerInfosForm = (props: SellerInfosFormProps) => {

  const { contact, setForm, label } = props;
  const { t } = useTranslation();

  const history = useHistory();
  const [formData, setFormData] = useState<SellerInfosFormData>({
    lastname: '',
    firstName: '',
    email: '',
    phoneNumber: '+33',
    verCode: '',
    enquiryReason: 'sellNow'
  });
  const [response, setResponse] = useState({ status: "", showAlert: false, isLoading: false, errorMessage: null });
  const [showModal, setShowModal] = useState(false);
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [contryCode, setContryCode] = useState<string | null>(null);

  const sendVerCode = () => {
    const phoneNumber = formData?.["phoneNumber"];

    if (phoneNumber) {
      API.sendSmsConfirmation({ phone: phoneNumber });
    }
  }

  const sendRequest = () => {
    setResponse(previousState => ({ ...previousState, isLoading: true }));
    if (contact && props.form.address) {
      const { address: {
        locality, administrative_area_level_1, country, street_number, route, postal_code
      } } = props.form;
      const { lastname, firstName, email, phoneNumber } = formData as SellerInfosFormData;

      API.sendContact({
        address: {
          locality: locality,
          administrativeAreaLevel1: administrative_area_level_1,
          country: country,
          streetNumber: street_number,
          route: route,
          postalCode: postal_code
        },
        information: {
          name: firstName,
          lastname: lastname,
          mail: email,
          phone: phoneNumber
        }
      }, () => {
        setResponse(previousState => ({ ...previousState, status: STATUS.SUCCESS, isLoading: false }));
        setTimeout(() => {
          history.push('/');
        }, 3000);
      }, (error: any) => {
        return setResponse(previousState => ({ ...previousState, errorMessage: error.message, status: STATUS.ERROR, showAlert: true, isLoading: false }));
      });
    } else {
      setResponse(previousState => ({ ...previousState, isLoading: false }));
      history.push("/step5");
    }
  }

  const getEstimation = (event: React.FormEvent<HTMLFormElement>) => {

    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      event.preventDefault();
      event.stopPropagation();

      setForm({
        ...props.form,
        sellerInfosForm: formData as SellerInfosFormData,
        step: 4
      });

      sendRequest();

      setShowModal(false);
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      event.preventDefault();
      event.stopPropagation();
      sendVerCode();
      setShowModal(true);
    }
  };


  const isValidNumber = (numstr: string) => {
    try {
      var hasDigits = parseDigits(numstr).length > 0;

      if (!hasDigits) {
        return false;
      }

      var parsedNumber = parsePhoneNumber(numstr, 'FR');
      var isValidPhoneNumber = parsedNumber.isValid() === true;

      return isValidPhoneNumber;
    } catch (e) {
      return false;
    }
  }

  const formatPhoneNumber = (numstr: string) => {
    try {
      var number = parsePhoneNumber(numstr, 'FR');

      return number.formatInternational();
    } catch (e: any) {
      // eslint-disable-next-line
      console.error(e.message);
      return numstr;
    }
  }

  const getNumberCountry = (numstr: string) => {
    try {
      return parsePhoneNumber(numstr, 'FR').country;

    } catch (e) {
      return '';
    }
  }

  const handleNumber = (e: any) => {
    var formattedNumber = formatPhoneNumber(e.target.value);
    var country = getNumberCountry(formattedNumber);
    if (country) {
      setContryCode(country);
    } else {
      setContryCode(null);
    }

    var isValidNumberInput = isValidNumber(e.target.value);
    if (!isValidNumberInput) {
      setIsPhoneError(true);
      return;
    }

    e.target.value = formattedNumber;
    setFormData({
      ...formData,
      [e.target.id]: formattedNumber.replaceAll(' ', ''),
    });
  };

  const handleChange = (e: any) => {
    setIsPhoneError(false);
    if (e.target.id === 'phoneNumber') {
      handleNumber(e);
    } else {
      setFormData({
        ...formData,
        [e.target.id]: e.target.value.trim(),
      });
    }

  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="box-container">


      <Modal dialogClassName='modal-dialog' contentClassName='modal-dialog-content' show={showModal} onHide={() => setShowModal(!showModal)} centered>
        <Form onSubmit={getEstimation}>
          <Modal.Header className="modal-dialog-header" closeButton>
            <Modal.Title className="modal-dialog-title">{t('step4.modal.title')}</Modal.Title>
          </Modal.Header>

          <Modal.Body className='modal-dialog-body'>
            <p className='modal-dialog-body_text'><span className='modal-dialog-body_text__strong'>{t('step4.modal.text')}</span><br /> {t('step4.modal.subText')}</p>
            <p className='modal-dialog-body_text modal-dialog-body_text__phone'><a href={"tel:" + formData?.["phoneNumber"]}>{formData?.["phoneNumber"]}</a></p>

            <Form.Group className="form-group-property-form" >
              <Form.Label className="form-label " htmlFor="verCode">{t('step4.modal.verCode')}</Form.Label>
              <Form.Control
                onChange={handleChange}
                type="text"
                size="lg"
                id="verCode"
                required
                className="form-control-pc-form" />
            </Form.Group>

          </Modal.Body>

          <Modal.Footer className="modal-dialog-footer" >
            <div className="btn-container-flex space-between no-padding">
              <Button variant="sextant-secondary" onClick={sendVerCode} className="no-padding text__left">{t('step4.modal.btnSecondary')}</Button>
              <Button variant="sextant" type="submit" size="lg">
                <b>{t('step4.modal.btn')}</b>
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>


      <Form onSubmit={onSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} className="form-group-property-form" controlId="lastname">
            <Form.Control
              onChange={handleChange}
              size="lg"
              type="text"
              placeholder={t('step4.lastname')}
              className="form-control-pc-form"
              required
            />
          </Form.Group>

          <Form.Group as={Col} className="form-group-property-form" controlId="firstName">
            <Form.Control
              onChange={handleChange}
              size="lg"
              type="text"
              placeholder={t('step4.firstName')}
              className="form-control-pc-form"
              required
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} className="form-group-property-form" controlId="email">
            <Form.Control
              onChange={handleChange}
              size="lg"
              type="email"
              placeholder={t('step4.email')}
              className="form-control-pc-form"
              required
            />
          </Form.Group>

          <Form.Group as={Col} className="form-group-property-form" controlId="phoneNumber">
            <Form.Control
              onChange={handleChange}
              size="lg"
              type="tel"
              placeholder={t('step4.phoneNumber')}
              className="form-control-pc-form"
              required
              isInvalid={isPhoneError}
            />
            <Form.Control.Feedback type="invalid">
              {t('step4.phoneNumberError')}
            </Form.Control.Feedback>
            {!isPhoneError && contryCode ? <div className="form-control-pc-form-country-code">
              {contryCode}
            </div> : <></>}
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group className="form-group-property-form" as={Col} controlId="enquiryReason">
            <Form.Select className="form-select-property-form" onChange={handleChange} required size="lg"  >
              <option value="">{t('step4.enquiryReason.0')}</option>
              <option value="sellNow">{t('step4.enquiryReason.1')}</option>
              <option value="sellWithinSixMonths">{t('step4.enquiryReason.2')}</option>
              <option value="sellInTheYear">{t('step4.enquiryReason.3')}</option>
              <option value="curious">{t('step4.enquiryReason.4')}</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="form-group-property-form" as={Col}>

          </Form.Group>
        </Row>
        <div className="cgu-text">
          <p>{t('step4.cgu.0')}</p>
          <p>
            {t('step4.cgu.1')}
            <a
              href="https://www.sextantfrance.fr/fr/cgu.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}{t('step4.cgu.2')}
            </a>{" "}
            {t('step4.cgu.3')}
            <a
              href="https://www.ppd-rgpd.com/fr/75011/75011"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}{t('step4.cgu.4')}
            </a>
            .
          </p>
        </div>
        <div className="btn-container-flex">
          <Button
            variant="sextant"
            type="submit"
            size="lg"
            disabled={response.isLoading || isPhoneError || formData.phoneNumber.length < 10}
            style={{ backgroundColor: "#FF685E" }}
          >
            {response.isLoading ? t('step4.cgu.5') : <b>{label}</b>}
          </Button>
        </div>
      </Form>

      {response.status === STATUS.ERROR ?
        <Alert dismissible key={"alert-response"} variant={"danger"} onClose={() => setResponse(previousState => ({ ...previousState, showAlert: false }))} show={response.showAlert}>
          {t('step4.errorAlert')}
          <br />
          {response.errorMessage}
          <div className="btn-container-flex">
            <Button onClick={sendRequest} disabled={response.isLoading} variant="sextant" size="lg">
              <b>{t('step4.errorBtn')}</b>
            </Button>
          </div>
        </Alert>
        : null}
      {response.status === STATUS.SUCCESS ?
        <Alert dismissible key={"alert-response"} variant={"success"} onClose={() => setResponse(previousState => ({ ...previousState, showAlert: false }))} show={response.showAlert}>
          {t('step4.successAlert')}
        </Alert>
        : null}
    </Container>
  );
};

export default SellerInfosForm;
