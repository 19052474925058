import '../styles/header.scss';
import { Form, Step } from '../interface/forms';
import HeaderInner from './header-inner';
import { useTranslation } from 'react-i18next';
interface HeaderProps {
    form: Form;
    setForm: (o: Form) => void;
    step: Step;
    address: string;
}



const HeaderContact = (props: HeaderProps) => {
    const { t } = useTranslation();
    return <>
        <header className="container-fluid header-img">
            <div className="header-container">
                <HeaderInner />
                <div className='header-wrapper'>

                    <div className="header-title header-title__short"><div className="header-title__strong">{t('contact.subText1')}</div>
                        <div className='header-title__sub'>{t('contact.mainText1')}</div>
                    </div>

                </div>
            </div>
        </header>
    </>
}

export default HeaderContact;