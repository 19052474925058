import React, { useState, ReactElement } from 'react';
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";
import { useTranslation } from 'react-i18next';

import './styles/application.scss';
import Footer from './components/footer';
import Header from './components/header';
import HeaderContact from './components/header-contact';
import Article from './components/article';
import { Form, Step } from './interface/forms';
import PropertyForm from './components/property-form';
import SaleForm from './components/sale-detail-form';
import SellerInfosForm from './components/seller-infos-form';
import EstimationSelector from './components/estimation-selector';
import EstimationCard from './components/estimation-card';
import EstimationHeader from './components/estimation-header';
import EstimationContactFormHeader from './components/estimation-contact-form-header';
import GaugeHeader from './components/gauge-header';
import AuthorizedRoute from './components/AuthorizedRouter';

import './i18/i18n';


const getAddress = (fullTextAddress: string | undefined): string => fullTextAddress ? fullTextAddress : "";
const getFormStep = (form: Form, step: Step): Step => form.step && step && form.step > step ? form.step : step;

const BodyContainer = (props: { component?: ReactElement<any, any> }) => <div className="body-container">
  {props.component ? props.component : null}
  <Article />
</div>

function App() {

  const [form, setForm] = useState<Form>({ step: 1, estimatedPrice: { salePrice: 0, saleMaxPrice: 0, latitude: 0, longitude: 0 } });
  const { t } = useTranslation();

  return (
    <div className="app-container">
      <BrowserRouter>
        <Switch>
          <AuthorizedRoute path="/estimation" isAllowed={form?.propertyPricePerEnergyLabels && form?.propertyPricePerEnergyLabels.length ? true : false}>
            <EstimationHeader address={getAddress(form.address?.fulltext)} />
            <BodyContainer component={<EstimationCard form={form} />} />
          </AuthorizedRoute>
          <AuthorizedRoute path="/contact" isAllowed={form?.address ? true : false}>
            <HeaderContact form={form} step={null} setForm={setForm} address={getAddress(form.address?.fulltext)} />
            <BodyContainer component={<SellerInfosForm contact={true} label={t('contact.btn')} form={form} setForm={setForm} />} />
          </AuthorizedRoute>
          <AuthorizedRoute path="/step5" isAllowed={form?.saleForm ? true : false}>
            <GaugeHeader address={getAddress(form.address?.fulltext)} form={form} setForm={setForm} />
            <BodyContainer />
          </AuthorizedRoute>
          <AuthorizedRoute path="/step4" isAllowed={form?.propertyForm?.propertyType && form?.saleForm?.personType ? true : false}>
            <EstimationContactFormHeader address={getAddress(form.address?.fulltext)} />
            <BodyContainer component={<SellerInfosForm contact={false} label={t('step4.btn')} form={form} setForm={setForm} />} />
          </AuthorizedRoute>
          <AuthorizedRoute path="/step3" isAllowed={form?.propertyForm ? true : false}>
            <Header form={form} step={getFormStep(form, 3)} setForm={setForm} />
            <BodyContainer component={<SaleForm form={form} setForm={setForm} />} />
          </AuthorizedRoute>
          <AuthorizedRoute path="/step2" isAllowed={form?.address?.fulltext ? true : false}>
            <Header form={form} step={getFormStep(form, 2)} setForm={setForm} />
            <BodyContainer component={<PropertyForm form={form} setForm={setForm} />} />
          </AuthorizedRoute>
          <AuthorizedRoute path="/step1" isAllowed={form?.address?.fulltext ? true : false}>
            <Header form={form} step={getFormStep(form, 1)} setForm={setForm} />
            <BodyContainer component={<EstimationSelector setForm={setForm} />} />
          </AuthorizedRoute>
          <Route exact path="/">
            <Header form={form} step={getFormStep(form, 1)} setForm={setForm} showSearch={true} />
            <BodyContainer />
          </Route>
          {/* redirect to home otherwise */}
          <Route path="*">
            <Header form={form} step={getFormStep(form, 1)} setForm={setForm} showSearch={true} />
            <BodyContainer />
          </Route>
        </Switch>
      </BrowserRouter>
      
      <Footer />
    </div>
  );
}

export default App;
