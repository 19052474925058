import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { useTranslation } from 'react-i18next';
import "../styles/search-input.scss";
import { GeoAlt } from 'react-bootstrap-icons';
import React from 'react';
import { Form } from '../interface/forms';
import { useHistory } from 'react-router';
import { GoogleAddressDetails } from '../interface/api';

interface SearchInputProps {
    form: Form;
    setForm: (o: Form) => void;
}

const getAddressInformations = (o: google.maps.GeocoderAddressComponent[]): GoogleAddressDetails => {
    return o.filter(item => item.types.includes("route")
        || item.types.includes("street_number")
        || item.types.includes("country")
        || item.types.includes("postal_code")
        || item.types.includes("administrative_area_level_1")
        || item.types.includes("locality"))
        .reduce((acc, item) => {
            if (item.types?.[0]) {
                return { ...acc, [item.types[0]]: item.long_name }
            }
            return { ...acc };
        }, {});
}

const SearchInput = (props: SearchInputProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [state, setState] = React.useState({ address: '' })

    const onChange = (address: string) => {
        setState({ address })
    }

    const onSelect = (address: string) => {
        const addressPromise: Promise<google.maps.GeocoderResult[]> = geocodeByAddress(address);
        const latLongPromise: Promise<google.maps.LatLngLiteral> = addressPromise.then(results => results[0])
            .then(latLng => getLatLng(latLng));

        Promise.all([addressPromise, latLongPromise])
            .then(response => ({ "address": response[0][0].address_components, "coordinates": response[1] }))
            .then(response => {
                props.setForm({ ...props.form, estimatedPrice: { latitude: response.coordinates.lat, longitude: response.coordinates.lng }, address: { ...getAddressInformations(response.address), "fulltext": address } });
                setState({ address })
                history.push("/step1");
            }).catch(error => console.error('Error', error));
    }

    return <div className="search-input-container">
        <PlacesAutocomplete
            /* ref={(c: any) => {
                        if (!c) return;
                        c.handleInputOnBlur = () => {};
            }}*/
            value={state.address}
            onChange={onChange}
            searchOptions={{
                componentRestrictions: { country: ['fr'] }
            }}
            onSelect={onSelect}>

            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                <div className="input-container">
                    <input id="search-input" {...getInputProps({
                        placeholder: t('address'),
                        className: 'location-search-input'
                    })} />

                    <div className="autocomplete-dropdown-container">
                        <div className="suggestion-item-container">
                            <div className="suggestion-item-list">
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? 'suggestion-item active'
                                        : 'suggestion-item';
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className
                                            })}
                                            key={suggestion.placeId}
                                        >
                                            <div className="suggestion-icon"><GeoAlt /></div>
                                            <div className="suggestion-description">{suggestion.description}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </PlacesAutocomplete>
    </div>;

}

export default SearchInput;