import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ChatLeft, X } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import '../styles/chat-widget.scss';
import API from '../api/api';

type message = {
    role: string,
    content: string
}

const ChatGPTWidget: React.FC = () => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [messages, setMessages] = useState<message[]>([{
        role: "system",
        content: t('widget.introText')
    }]);
    const [input, setInput] = useState<string>('');
    const chatContainerRef = useRef<HTMLDivElement | null>(null);

    const toggleChatWidget = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleSendMessage();
        }
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInput(event.target.value);
    };

    const handleSendMessage = useCallback(async () => {
        if (input.trim() === '') return;

        const newChatLog = [...messages, ...[{ role: "user", content: `You: ${input}` }]];

        setMessages(newChatLog);
        setInput('');
        setIsPending(true);

        try {
            API.getChat({
                messages: newChatLog,
            }, response => {
                
                let newMessage = '';
                if(response.ErrorMessage) {
                    newMessage = response.ErrorMessage.replace(/(\d+\.\d*\.?)/g, '<br/> $1');
                } else {
                    newMessage = response.choices[0].message.content.replace(/(\d+\.\d*\.?)/g, '<br/> $1');
                }
                setMessages([...newChatLog, ...[{ role: "assistant", content: `${newMessage}` }]]);
                setIsPending(false);
            }, reject => {
                setIsPending(false);
                let newMessage ='Error sending message';
                setMessages([...newChatLog, ...[{ role: "assistant", content: `${newMessage}` }]]);
            });

        } catch (error) {
            setIsPending(false);
            console.error('Error: ', error);
        }
    }, [messages, input]);

    return (
        <div className={`chat-widget-container ${isOpen ? 'open' : ''}`}>
            <button className={`chat-widget-button ${!isOpen ? "chat-widget-button__closed" : ""}`} onClick={toggleChatWidget}>
                {isOpen ? <X size="25px" /> :
                    <>
                        <ChatLeft size="25px" />
                        <div className="chat-widget-icon-container">
                            <div className="dot">.</div>
                            <div className="dot">.</div>
                            <div className="dot">.</div>
                        </div></>}
            </button>

            {isOpen && (<div className="chat-widget">
                <div className="chat-widget-header">{t('widget.title')}</div>
                <div ref={chatContainerRef} className="chat-widget-messages">
                    {messages.map((message, index) => (
                        <div key={index} className="chat-widget-message">
                            <div dangerouslySetInnerHTML={{ __html: message.content }} />
                        </div>
                    ))}
                </div>
                <div className="chat-widget-footer-wrapper">
                    <div className="chat-widget-input">
                        <input
                            type="text"
                            value={input}
                            onChange={handleInputChange}
                            onKeyDown={event => handleKeyPress(event)}
                            placeholder={t('widget.inputPlaceholder')}
                        />
                        <button className="chat-widget-send-button" disabled={isPending || !input.length} onClick={handleSendMessage}>
                            {isPending ?
                                (<Spinner animation="border" variant="light" role="status" size="sm">
                                    <span className="visually-hidden">{t('widget.loadingText')}</span>
                                </Spinner>)
                                : t('widget.actionBtn')}
                        </button>
                    </div>
                    <div className="chat-widget-footer">
                        <a target="_blank" href="https://www.sextantfrance.fr/fr/contact.htm" rel="noreferrer">{t('widget.contactUs')}</a>
                        <a target="_blank" href="https://www.sextantfrance.fr/fr/agents-map.htm" rel="noreferrer">{t('widget.ourAdvisors')}</a>
                    </div>
                </div>
            </div> )}
        </div>
    );
};

export default ChatGPTWidget;