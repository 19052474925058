import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import building from "../assets/building-house.png";
import trees from "../assets/trees.png";
import earth from "../assets/earth.png";
import awards from "../assets/awards.png";
import treesBg from "../assets/trees-bg.png";

import homeImgLeft from '../assets/home-img_left.png';
import homeimgRight from '../assets/home-img_right.png';

import dotsBlue from '../assets/dots-blue.png';
import dotsOrange from '../assets/dots-orange.png';
import vector from '../assets/vector.png';

import "../styles/article.scss";

const divStyle = {
    background: 'url(' + awards + ') no-repeat',
    height: '100%',
    width: '100%'
};

const Article = () => {
    const { t } = useTranslation();

    return <div className="article-container">
        <Container>
            <Row className="article article-cell__center article__top article__first ">
                <img src={vector} alt="acoompagnement agent" className="img-center-layer" />
                <Col sm={7}>
                    <div className="article-cell">
                        <div className="article-cell-header">
                            <div className="article-cell-icon">
                                <img src={building} alt="acoompagnement agent" />
                            </div>
                            <div className="title">{t('article1.subText1')} <div className="title-subtitle">{t('article1.mainText1')} <div className="title-lightblue">{t('article1.mainText2')}</div></div></div>
                        </div>
                        <div className="boldText">{t('article1.description1')} <span className="text">{t('article1.description2')}</span></div>

                    </div>
                </Col>
                <Col sm={5} className="article-column article-cell__center">
                    <img src={dotsOrange} alt="acoompagnement agent" className="img-first-layer" />
                    <div className="article-cell article-cell-img img-container img-two">
                        <img src={homeImgLeft} alt="acoompagnement agent" />
                        <img src={homeimgRight} alt="acoompagnement agent" />
                    </div>
                </Col>
            </Row>
            <div className="article-transparent article-grid-layout">
                <div className="article-column__no-borders  article-cell__center">
                    <div className="article-cell-img">
                        <img src={treesBg} alt="acoompagnement agent" className='article-cell-img__trees-bg' />
                    </div>
                </div>
                <div className="article-column__no-borders article-grid-column">
                        <div className="article-cell-header">
                            <div className="article-cell-icon">
                                <img src={trees} alt="acoompagnement agent" />
                            </div>
                            <div className="title">{t('article2.subText1')} <div className="title-subtitle">{t('article2.mainText1')} <div className="title-lightblue">{t('article2.mainText2')} </div></div></div>
                        </div>
                        <div className="boldText">{t('article2.description1')}<span className="text">{t('article2.description2')}</span></div>
                </div>
            </div>
            <Row className="article article-cell__center article-spec-bg">
                <img src={dotsBlue} alt="acoompagnement agent" className="img-center-layer img-center-layer__top" />
                <Col sm={7}>
                    <div className="article-cell">
                        <div className="article-cell-header">
                            <div className="article-cell-icon">
                                <img src={earth} alt="acoompagnement agent" />
                            </div>
                            <div className="title">{t('article3.subText1')} <div className="title-subtitle">{t('article3.mainText1')} <div className="title-lightblue">{t('article3.mainText2')}</div></div></div>
                        </div>
                        <div className="boldText">{t('article3.description1')} <span className="text">{t('article3.description2')}</span> </div>

                    </div>
                </Col>
                <Col sm={5} className="article-column__no-borders article-cell__spec-border">
                    <div className="img-spec-bg">
                        <div style={divStyle} />
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
}

export default Article;