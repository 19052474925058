import React, { ReactNode } from 'react';
import { Redirect, Route, RouteProps } from "react-router";

interface AuthorizedRouteProps extends RouteProps{
    isAllowed: boolean;
}

/**
 * A custom route using a props 'isAllowed' to display child components 
 * or redirect to application route if isAllowed is false
 */
const AuthorizedRoute = ({ children, isAllowed, ...rest } : AuthorizedRouteProps) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAllowed
                    ? React.Children.map<ReactNode, ReactNode>(children, child => {
                        if(React.isValidElement(child)) {
                          return React.cloneElement(child, props
                          )
                        }
                      })
                    : <Redirect
                        to={"/"}
                    />}
        />
    );
}

export default AuthorizedRoute;