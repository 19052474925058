import React, {useEffect} from "react";
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import { Col, Container, Form, Row } from "react-bootstrap"
import renovation1 from "../assets/renovation1.png";
import renovation2 from "../assets/renovation2.png";
import renovation3 from "../assets/renovation3.png";
import dpeBg from "../assets/dpe-bg.png";
import dpe from "../assets/dpe.png";
import dotsBlue from '../assets/dots-blue.png';
import Button from 'react-bootstrap/Button';
import { Form as FormType, SaleFormData } from "../interface/forms";

interface SaleFormProps {
    form: FormType;
    setForm: (o: FormType) => void;
}

enum InputTypes {
    Boolean = 'boolean',
    Number = 'number'
}

const SaleForm = (props: SaleFormProps) => {
    const history = useHistory();

    const { t } = useTranslation();

    const [formData, setFormData] = React.useState<SaleFormData>(props.form?.saleForm ?? {
        personType: 'owner',
        energyLevel: 'g'
    });

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            event.preventDefault();
            event.stopPropagation();
            props.setForm({ ...props.form, saleForm: formData });

            history.push("/step4");
        }
    }

    const handleChange = (e: any) => {
        let value = e.target.value.trim();

        switch (e.target.dataset.type) {
            case InputTypes.Boolean:
                value = Boolean(e.target.value);
                break;
            case InputTypes.Number:
                value = Number(e.target.value);
                break;
        }

        setFormData({
            ...formData,
            [e.target.id]: value
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Container className="box-container">
                <Form onSubmit={onSubmit}>
                    <Row>
                        <Col>
                            <Row className="mb-3">
                                <Form.Group className="form-group-property-form" as={Col} controlId="personType">
                                    <Form.Select className="form-select-property-form" required onChange={handleChange} size="lg" >
                                        <option value="">{t('step3.personType.0')}</option>
                                        <option value="owner">{t('step3.personType.1')}</option>
                                        <option value="buyer">{t('step3.personType.2')}</option>
                                        <option value="tenant">{t('step3.personType.3')}</option>
                                        <option value="agent">{t('step3.personType.4')}</option>
                                        <option value="other">{t('step3.personType.5')}</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group className="form-group-property-form" as={Col} controlId="relationToProperty">
                                    <Form.Select className="form-select-property-form" onChange={handleChange} size="lg">
                                        <option>{t('step3.relationToProperty.0')}</option>
                                        <option value="mainResidence">{t('step3.relationToProperty.1')}</option>
                                        <option value="secondHome">{t('step3.relationToProperty.2')}</option>
                                        <option value="buyToLeft">{t('step3.relationToProperty.3')}</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group className="form-group-property-form" as={Col} controlId="datePurchase">
                                    <Form.Label>{t('step3.datePurchaseLabel')}</Form.Label>
                                    <Form.Control data-type='number' min={1900} onChange={handleChange} size="lg" type="number" placeholder={t('step3.datePurchase')} />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group className="form-group-property-form" as={Col} controlId="purchasePrice">
                                    <Form.Label>{t('step3.purchasePriceLabel')}</Form.Label>
                                    <Form.Control data-type='number' min={0} step={1000} onChange={handleChange} size="lg" type="number" placeholder={t('step3.purchasePrice')} />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group className="form-group-property-form" as={Col} controlId="sellingPrice">
                                    <Form.Label>{t('step3.sellingPriceLabel')}</Form.Label>
                                    <Form.Control data-type='number' min={0} step={1000} onChange={handleChange} size="lg" type="number" placeholder={t('step3.sellingPrice')} />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group className="form-group-property-form" as={Col} controlId="hasRecentHeavyRenovation">
                                    <div className="form-control-container">
                                        <div className="form-control-label">
                                            <Form.Label className="form-label">{t('step3.hasRecentHeavyRenovation')}</Form.Label>
                                        </div>
                                        <div className="form-control-container radio_container">
                                            <Form.Check
                                                onChange={handleChange}
                                                name="group1"
                                                type={"radio"}
                                                label={t('step3.checkbox.0')}
                                                data-type='boolean'
                                                id={'hasRecentHeavyRenovation'}
                                                value={"true"}
                                                className="form-control-pc-form form-control__radio"
                                            />
                                            <Form.Check
                                                onChange={handleChange}
                                                defaultChecked
                                                name="group1"
                                                type={"radio"}
                                                label={t('step3.checkbox.1')}
                                                data-type='boolean'
                                                id={`hasRecentHeavyRenovation-radio-n`}
                                                value={"false"}
                                                className="form-control-pc-form form-control__radio"
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group className="form-group-property-form" as={Col} controlId="hasRecentRenovation">
                                    <div className="form-control-container">
                                        <div className="form-control-label">
                                            <Form.Label className="form-label">{t('step3.hasRecentRenovation')}</Form.Label>
                                        </div>
                                        <div className="form-control-container radio_container">
                                            <Form.Check
                                                onChange={handleChange}
                                                name="group2"
                                                type={"radio"}
                                                label={t('step3.checkbox.0')}
                                                id={'hasRecentRenovation'}
                                                data-type='boolean'
                                                value={"true"}
                                                className="form-control-pc-form form-control__radio"
                                            />
                                            <Form.Check
                                                onChange={handleChange}
                                                name="group2"
                                                type={"radio"}
                                                defaultChecked
                                                label={t('step3.checkbox.1')}
                                                id={`hasRecentRenovation-radio-n`}
                                                data-type='boolean'
                                                value={"false"}
                                                className="form-control-pc-form form-control__radio"
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group className="form-group-property-form" as={Col} controlId="needRenovation">
                                    <div className="form-control-container">
                                        <div className="form-control-label">
                                            <Form.Label className="form-label">{t('step3.needRenovation')}</Form.Label>
                                        </div>
                                        <div className="form-control-container radio_container">
                                            <Form.Check
                                                onChange={handleChange}
                                                name="group3"
                                                type={"radio"}
                                                label={t('step3.checkbox.0')}
                                                id={'needRenovation'}
                                                data-type='boolean'
                                                value={"true"}
                                                className="form-control-pc-form form-control__radio"
                                            />
                                            <Form.Check
                                                onChange={handleChange}
                                                defaultChecked
                                                name="group3"
                                                type={"radio"}
                                                label={t('step3.checkbox.1')}
                                                id={`needRenovation-radio-n`}
                                                data-type='boolean'
                                                value={"false"}
                                                className="form-control-pc-form form-control__radio"
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group className="form-group-property-form" as={Col} controlId="isRenovationRequired">
                                    <div className="form-control-container">
                                        <div className="form-control-label"><Form.Label className="form-label">{t('step3.isRenovationRequired')}</Form.Label></div>
                                        <div className="form-control-container radio_container">
                                            <Form.Check
                                                onChange={handleChange}
                                                name="group4"
                                                type={"radio"}
                                                label={t('step3.checkbox.0')}
                                                id={'isRenovationRequired'}
                                                data-type='boolean'
                                                value={"true"}
                                                className="form-control-pc-form form-control__radio"
                                            />
                                            <Form.Check
                                                onChange={handleChange}
                                                defaultChecked
                                                name="group4"
                                                type={"radio"}
                                                label={t('step3.checkbox.1')}
                                                id={`isRenovationRequired-radio-n`}
                                                data-type='boolean'
                                                value={"false"}
                                                className="form-control-pc-form form-control__radio"
                                            />
                                        </div></div>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3 form-row-container form-row-container__center">
                                <Col sm={7}>
                                    <Form.Group className="form-group-property-form" controlId="energyLevel">
                                        <Form.Select className="form-select-property-form" required onChange={handleChange} size="lg">
                                            <option>{t('step3.energyLevel.0')}</option>
                                            <option value="a">A</option>
                                            <option value="b">B</option>
                                            <option value="c">C</option>
                                            <option value="d">D</option>
                                            <option value="e">E</option>
                                            <option value="f">F</option>
                                            <option value="g">G</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col sm={5}>
                                    <div className="btn-container-flex btn-container-flex__short">
                                        <Button variant="sextant" type="submit" size="lg">
                                            <b>{t('step3.btn')}</b>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col className={"justify-flex gap-2"}>
                            <img src={renovation3} alt="building preview" />
                            <img src={renovation2} alt="building preview" />
                            <img src={renovation1} alt="building preview" />
                        </Col>
                    </Row>

                </Form>
            </Container>
            <div className="article-container article-container__margin-top ">
                <Container>
                    <Row className="article article__fit-content article__sale-form ">
                        <Col sm={5} className="article-cell__no-borders article-cell__center  article-cell__sale-form_img">
                            <img src={dotsBlue} alt="acoompagnement agent" className="img-first-layer img-first-layer__down" />
                            <div className="article-cell-img">
                                <img src={dpeBg} className="img-full" alt="acoompagnement agent" />
                            </div>
                        </Col>
                        <Col sm={7}>
                            <div className="article-cell article-cell__full-width article-cell-no-borders article-cell-no-borders-text">
                                <div className="article-cell-header">
                                    <div className="title">{t('step3.article.subText1')} <div className="title-subtitle">{t('step3.article.mainText1')} <div className="title-lightblue">{t('step3.article.mainText2')}</div></div></div>
                                </div>
                                <img src={dpe} className="img-sale" alt="acoompagnement agent" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default SaleForm;