import { useTranslation } from 'react-i18next';
import "../styles/estimation-selector.scss";
import onlineLogo from "../assets/online_estimation_logo.png";
import agentLogo from "../assets/agent_estimation_logo.png";
import checkMark from "../assets/checkmark.png";
import { Container } from "react-bootstrap";
import { useHistory } from 'react-router';
import { Form } from "../interface/forms";

interface EstimationSelectorProps {
  setForm: React.Dispatch<React.SetStateAction<Form>>
}

const EstimationSelector = (props: EstimationSelectorProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  return <Container className="box-container">
    <div className='estimation-box'>
      <div className="box leftBox" onClick={() => history.push("/contact")} >
        <img className="logo" src={agentLogo} alt="agentLogo" />
        <h2>{t('step1.box1.title')}</h2>
        <div className="buttonsText">
          <div>
            <img src={checkMark} alt="Check" /> {t('step1.box1.points.0')}
          </div>
          <div>
            <img src={checkMark} alt="Check" /> {t('step1.box1.points.1')}
          </div>
          <div>
            <img src={checkMark} alt="Check" /> {t('step1.box1.points.2')}
          </div>
        </div>
      </div>
      <div className="box rightBox" onClick={() => { props.setForm((previousState: Form) => ({ ...previousState, step: previousState.step && previousState.step > 2 ? previousState.step : 2 })); history.push("/step2") }}>
        <img className="logo" src={onlineLogo} alt="onlineLogo" />
        <h2>{t('step1.box2.title')}</h2>
        <div className="buttonsText">
          <div>
            <img src={checkMark} alt="Check" /> {t('step1.box2.points.0')}
          </div>
          <div>
            <img src={checkMark} alt="Check" /> {t('step1.box2.points.1')}
          </div>
          <div>
            <img src={checkMark} alt="Check" /> {t('step1.box2.points.2')}
          </div>
        </div>
      </div>
    </div>
  </Container>
};

export default EstimationSelector;
