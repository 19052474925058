import React, {useEffect} from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap"
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { Form as FormType, PropertyFormData } from "../interface/forms";

interface PropertyFormProps {
    form: FormType;
    setForm: React.Dispatch<React.SetStateAction<FormType>>;
}

enum InputTypes {
    Boolean = 'boolean',
    Number = 'number'
}

const PropertyForm = (props: PropertyFormProps) => {
    const history = useHistory();

    const { t } = useTranslation();

    const [formData, setFormData] = React.useState<PropertyFormData>(props.form?.propertyForm ?? {
        propertyType: "none",
        livingArea: 0,
        landArea: 0,
        hasLift: false,
        hasParking: false,
        hasPool: false,
        numberOfRooms: 0,
        buildingYear: 2000
    });

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            event.preventDefault();
            event.stopPropagation();
            props.setForm(previousState => ({ ...previousState, step: 3, propertyForm: formData }));

            history.push("/step3");
        }
    }

    const handleChange = (e: any) => {
        let value = e.target.value.trim();

        switch (e.target.dataset.type) {
            case InputTypes.Boolean:
                value = Boolean(e.target.value);
                break;
            case InputTypes.Number:
                value = Number(e.target.value);
                break;
        }

        setFormData({
            ...formData,
            [e.target.id]: value
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container className="box-container">
            <Form onSubmit={onSubmit}>
                <Row className="mb-3">
                    <Form.Group className="form-group-property-form" as={Col} controlId="propertyType">
                        <Form.Select data-type='string' className="form-select-property-form" onChange={handleChange} required size="lg" >
                            <option value="">{t('step2.propertyType.0')}</option>
                            <option value="apartment">{t('step2.propertyType.1')}</option>
                            <option value="house">{t('step2.propertyType.2')}</option>
                        </Form.Select>
                    </Form.Group>

                    
                    <Form.Group className="form-group-property-form" as={Col} controlId="heatingType">
                        
                        <Form.Select onChange={handleChange}
                            size="lg"
                            data-type='string'
                            className="form-select-property-form"
                        >
                            <option>{t('step2.heatingType.0')}</option>
                            <option value="district">{t('step2.heatingType.1')}</option>
                            <option value="electric">{t('step2.heatingType.2')}</option>
                            <option value="floorHeating">{t('step2.heatingType.3')}</option>
                            <option value="heatPumpAir">{t('step2.heatingType.4')}</option>
                            <option value="gas">{t('step2.heatingType.5')}</option>
                            <option value="oil">{t('step2.heatingType.6')}</option>
                        </Form.Select>
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group className="form-group-property-form" as={Col} controlId="livingArea">
                        <Form.Label>{t('step2.livingAreaLabel')}</Form.Label>
                        <Form.Control min={0}
                            onChange={handleChange}
                            required
                            size="lg"
                            type="number"
                            data-type='number'
                            placeholder={t('step2.livingArea')}
                            className="form-control-pc-form" />
                    </Form.Group>

                    <Form.Group className="form-group-property-form" as={Col} controlId="landArea" style={{visibility: formData.propertyType === 'apartment' ? 'hidden' : 'visible'}}>
                        <Form.Label>{t('step2.landAreaLabel')}</Form.Label>
                        <Form.Control min={0}
                            onChange={handleChange}
                            size="lg"
                            type="number"
                            data-type='number'
                            required={formData.propertyType !== 'apartment'}
                            placeholder={t('step2.landArea')}
                            className="form-control-pc-form" />
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group className="form-group-property-form" as={Col} controlId="numberOfRooms">
                        <Form.Label>{t('step2.numberOfRoomsLabel')}</Form.Label>
                        <Form.Control min={0}
                            onChange={handleChange}
                            size="lg"
                            type="number"
                            data-type='number'
                            required
                            placeholder={t('step2.numberOfRooms')}
                            className="form-control-pc-form" />
                    </Form.Group>

                    <Form.Group className="form-group-property-form" as={Col} controlId="buildingYear">
                        <Form.Label>{t('step2.buildingYearLabel')}</Form.Label>
                        <Form.Control className="form-control-pc-form" onChange={handleChange}
                            size="lg"
                            type="number"
                            data-type='number'
                            placeholder={t('step2.buildingYear')}
                            required />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group className="form-group-property-form" as={Col} controlId="nubmerOfBedrooms">
                        <Form.Label>{t('step2.nubmerOfBedroomsLabel')}</Form.Label>
                        <Form.Control min={0}
                            onChange={handleChange}
                            size="lg"
                            type="number"
                            data-type='number'
                            placeholder={t('step2.nubmerOfBedrooms')}
                            className="form-control-pc-form" />
                    </Form.Group>

                    <Form.Group className="form-group-property-form" as={Col} controlId="hasPool">
                        <div className="form-control-container form-checkbox">
                            <div className="form-control-label"><Form.Label className="form-label">{t('step2.hasPool')}</Form.Label></div>
                            <div className="form-control-container radio_container">
                                <Form.Check
                                    id={'Piscine-yes'}
                                    onChange={handleChange}
                                    inline
                                    label={t('step2.checkbox.0')}
                                    name="Piscine"
                                    type="radio"
                                    value="true"
                                    data-type='boolean'
                                    className="form-control-pc-form form-control__radio"
                                />
                                <Form.Check
                                    id={'Piscine-no'}
                                    onChange={handleChange}
                                    inline
                                    label={t('step2.checkbox.1')}
                                    name="Piscine"
                                    type="radio"
                                    value="false"
                                    data-type='boolean'
                                    defaultChecked
                                    className="form-control-pc-form form-control__radio"
                                />
                            </div>
                        </div>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group className="form-group-property-form" as={Col} controlId="numberOfBathrooms">
                        <Form.Label>{t('step2.numberOfBathroomsLabel')}</Form.Label>
                        <Form.Control min={0}
                            onChange={handleChange}
                            size="lg"
                            type="number"
                            data-type='number'
                            placeholder={t('step2.numberOfBathrooms')}
                            className="form-control-pc-form" />
                    </Form.Group>
                    <Form.Group className="form-group-property-form" as={Col} controlId="hasLift">
                        <div className="form-control-container form-checkbox">
                            <div className="form-control-label"><Form.Label className="form-label">{t('step2.hasLift')}</Form.Label></div>
                            <div className="form-control-container radio_container">
                                <Form.Check
                                    id={'Ascenceur-yes'}
                                    onChange={handleChange}
                                    inline
                                    label={t('step2.checkbox.0')}
                                    name="Ascenceur"
                                    type="radio"
                                    value="true"
                                    data-type='boolean'
                                    className="form-control-pc-form form-control__radio"
                                />
                                <Form.Check
                                    id={'Ascenceur-no'}
                                    onChange={handleChange}
                                    inline
                                    label={t('step2.checkbox.1')}
                                    name="Ascenceur"
                                    type="radio"
                                    value="false"
                                    data-type='boolean'
                                    defaultChecked
                                    className="form-control-pc-form form-control__radio"
                                />
                            </div>
                        </div>
                    </Form.Group>
                </Row>


                <Row className="mb-3">
                    <Form.Group className="form-group-property-form" as={Col} controlId="floor" style={{visibility: formData.propertyType === 'house' ? 'hidden' : 'visible'}}>
                        <Form.Label>{t('step2.floorLabel')}</Form.Label>
                        <Form.Control min={0}
                            onChange={handleChange}
                            size="lg"
                            type="number"
                            data-type='number'
                            placeholder={t('step2.floor')}
                            className="form-control-pc-form" />
                    </Form.Group>
                    <Form.Group className="form-group-property-form" as={Col} controlId="hasParking">
                        <div className="form-control-container form-checkbox">
                            <div className="form-control-label"><Form.Label className="form-label">{t('step2.hasParking')}</Form.Label></div>
                            <div className="form-control-container radio_container">
                                <Form.Check
                                    id={'Parking-yes'}
                                    onChange={handleChange}
                                    inline
                                    label={t('step2.checkbox.0')}
                                    name="Parking"
                                    type="radio"
                                    value="true"
                                    data-type='boolean'
                                    className="form-control-pc-form form-control__radio"
                                />
                                <Form.Check
                                    id={'Parking-no'}
                                    onChange={handleChange}
                                    inline
                                    label={t('step2.checkbox.1')}
                                    name="Parking"
                                    type="radio"
                                    value="false"
                                    data-type='boolean'
                                    defaultChecked
                                    className="form-control-pc-form form-control__radio"
                                />
                            </div>
                        </div>
                    </Form.Group>
                </Row>

                <div className="btn-container-flex">
                    <Button variant="sextant" type="submit" size="lg">
                        <b>{t('step2.btn')}</b>
                    </Button>
                </div>
            </Form >
        </Container >
    )
}

export default PropertyForm;