import '../styles/header.scss';
import HeaderInner from './header-inner';
import { useTranslation } from 'react-i18next';
interface EstimationHeaderProps {
    address: string;
}

const EstimationContactFormHeader = (props: EstimationHeaderProps) => {
    const { t } = useTranslation();
    return <>
        <header className="container-fluid header-img">
            <div className="header-container">
                <HeaderInner />
                <div className='header-wrapper'>
                    <div className="header-title header-title__short"><div className="header-title__strong">{t('step4.header.mainText1')}</div>
                        <div className='header-title__sub'>{t('step4.header.subText1')}</div>
                    </div>
                </div>
            </div>
        </header>
    </>
}

export default EstimationContactFormHeader;