import axios from "axios";
import { SendSmsRequest, EstimatePropertyRequest, Response,SendContactRequest } from "../interface/api";
import { BACKEND_URL } from "../utils/utils";

const url = BACKEND_URL ? BACKEND_URL : '';

const API = {
    postEstimation: (data?: EstimatePropertyRequest, callback?: (o: Response) => void, reject?: (error: any) => void): void => {
        axios.post(`${url}/api/public/estimation-portal/estimate-property`, data)
            .then((res) => {
                if (callback) callback(res.data);
            }).catch(err => {
                if (reject) reject(err);
            })
    },
    sendSmsConfirmation: (data: SendSmsRequest, callback?: (o: any) => void) => {
        axios.post(`${url}/api/public/sms-confirmation/send`, data)
            .then((res) => {
                if (callback) callback(res.data);
            }).catch(err => {
                console.error(err);
            })
    },
    sendContact: (data: SendContactRequest, callback?: (o: any) => void, reject?: (error: any) => void): void => {
        axios.post(`${url}/api/public/estimation-portal/send-contact`, data)
            .then((res) => {
                if (callback) callback(res.data);
            }).catch(err => {
                if (reject) reject(err);
            })
    },
    getChat: (data: any, callback?: (o: any) => void, reject?: (error: any) => void): void => {
        axios.post(`${url}/api/chat`, data)
            .then((res) => {
                if (callback) callback(res.data);
            }).catch(err => {
                if (reject) reject(err);
            })
    },
}

export default API; 