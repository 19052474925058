import React from 'react';
import '../styles/header.scss';
import '../styles/language.scss';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import LogoImg from "../assets/sextant-logo-vector.svg";

type Languages = {
    [key: string]: { nativeName: string, fullName: string }
}

const lngs: Languages = {
    fr: { nativeName: 'Fr', fullName: 'French' },
    en: { nativeName: 'En', fullName: 'English' }
};

type CustomToggleProps = {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
};

const CustomToggle = React.forwardRef((props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            if (props.onClick) props.onClick(e);
        }}
        className='language-custom-toogle'
    >
        {props.children}
    </a>
));

const HeaderInner = () => {
    const { i18n } = useTranslation();
    const langs = Object.keys(lngs);

    return <>
        <div className='header-inner'>
            <a className="sextant-logo" href="/"><img src={LogoImg} alt="logo Sextant" /></a>

            <div>
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-lang">
                        {i18n.resolvedLanguage}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='language-dropdown'>
                        {langs.map((lng) => (
                            <Dropdown.Item key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} onClick={() => i18n.changeLanguage(lng)} className='language-item'>{lngs[lng].fullName}</Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>

    </>
}

export default HeaderInner;