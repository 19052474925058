import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import "../styles/estimation-card.scss";
import lowEstimateSrc from "../assets/low-estimation.png";
import highEstimateSrc from "../assets/high-estimation.png";
import estimationArrow from '../assets/estimation-arrow.png'
import { Container } from "react-bootstrap";
import StreetView from "./street-view";
import EstimationArticle from './estimation-article';
import ChatGPTWidget from '../components/chat-widget';
import { Form as FormType } from "../interface/forms";


const getPrice = (price: number) => {
    return new Intl.NumberFormat('de-DE', {
        style: 'currency', currency: 'EUR', useGrouping: true,
        maximumSignificantDigits: 5,
    }).format(price).replaceAll('.', ' ')
}

interface EnergyLevelProps {
    letter?: string;
    index: number;
    currentIndex?: number;
    showClick?: boolean;
    selectItem: (index: number) => void;
}

const energyColors = ["#00A439", "#76B82A", "#BCCF00", "#FFD500", "#F7A600", "#EF7D00", "#E30613",];

const EnergyLevel = ({ letter = 'G', index = 0, currentIndex, selectItem, showClick }: EnergyLevelProps) => {
    const color = energyColors[index];


    const getClassNames = () => {
        let str: string = 'energy-level';

        if (!!showClick) {
            str += ' energy-level_hover';
        }

        if (currentIndex === index) {
            str += ' energy-level_scale';
        }

        return str;
    }

    return (
        <div className={getClassNames()} style={{ width: `calc(105% - ${(7 - index) * 10}%)` }} onClick={() => selectItem(index)}>
            <div className='energy-level-text' style={{ width: '100%', backgroundColor: color }}>{letter}</div>
            <div className='energy-level-triangle' style={{ borderColor: `transparent transparent transparent ${color}` }}></div>
        </div >
    );
}

type EnergyContainerProps = {
    energyArray: Array<any>;
    clickable?: boolean;
    startLetter?: string;
}

const EnergyContainer = (props: EnergyContainerProps) => {

    const { t } = useTranslation();

    const { energyArray, clickable, startLetter } = props;

    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [pricePerSquareMeter, setPricePerSquareMeter] = useState<number>(0);
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const showPrice = (index: number) => {
        setTotalPrice(energyArray[index].totalPrice);
        setPricePerSquareMeter(energyArray[index].pricePerSquareMeter);
        setCurrentIndex(index);
    }

    const findLetterIndex = useMemo(() => {
        return !!startLetter ? energyArray.findIndex(el =>
            el.energyLabel === startLetter) : 0
    }, [energyArray, startLetter]);

    useEffect(() => {
        if (energyArray.length) {
            setTotalPrice(energyArray[findLetterIndex].totalPrice);
            setPricePerSquareMeter(energyArray[findLetterIndex].pricePerSquareMeter);
            setCurrentIndex(findLetterIndex);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [energyArray, findLetterIndex]);


    return (
        <div className='energy-container'>
            <div className='energy-wrapper'>
                {energyArray.map((item, index) => (
                    <EnergyLevel
                        key={item.energyLabel + index + Math.floor(Math.random() * (1000 - 100) + 100)}
                        letter={item.energyLabel}
                        index={index}
                        currentIndex={currentIndex}
                        selectItem={(i) => { if (clickable) showPrice(i) }}
                        showClick={clickable}
                    />
                ))}

                <div className='energy-text'>
                    <span>{t('estimation.DPE.2')} : {getPrice(totalPrice)}</span>
                    <br />
                    <span>{getPrice(pricePerSquareMeter)}/m²</span>
                </div>

            </div>
        </div>
    );
}

interface EstimationCardProps {
    low?: number;
    high?: number;
    form: FormType;
}

const EstimationCard = (props: EstimationCardProps) => {
    const { t } = useTranslation();
    const { form } = props;
    const { propertyPricePerEnergyLabels, saleForm } = form;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <div>
        {form && propertyPricePerEnergyLabels && saleForm &&
            <>
                <Container className="box-container estimation-container">
                    <StreetView form={form} />
                    <div className="card-container">
                        <div className="card left-card">
                            <img src={lowEstimateSrc} alt="Estimation basse" />
                            <span className="card-title">{t('estimation.cards.0')}</span>
                            <span className="card-number">{propertyPricePerEnergyLabels[propertyPricePerEnergyLabels.length - 1] ? getPrice(propertyPricePerEnergyLabels[propertyPricePerEnergyLabels.length - 1].totalPrice): 0 }</span>
                        </div>
                        <div className="card right-card">
                            <img src={highEstimateSrc} alt="Estimation haute" />
                            <span className="card-title">{t('estimation.cards.1')}</span>
                            <span className="card-number">{propertyPricePerEnergyLabels[0] ? getPrice(propertyPricePerEnergyLabels[0].totalPrice) : 0}</span>
                        </div>
                    </div>
                </Container>
                <div className='estimation-container'>
                    <div className='estimation-container-wrapper'>
                        <div className='estimation-header'>
                            <div className="title marginVertical32"><span className="title-subtitle">{t('estimation.middleTitle.subText1')} <span className="title-lightblue">{t('estimation.middleTitle.subText2')}</span> {t('estimation.middleTitle.subText3')}</span></div>
                            <div className="text"><span className="boldText">{t('estimation.middleTitle.mainText1')}</span> {t('estimation.middleTitle.mainText2')}</div>
                        </div>

                        <div className="card-wrapper">
                            <div className='estimation-img'>
                                <img src={estimationArrow} alt="acoompagnement agent" />
                            </div>
                            <div className='card-dpe'>
                                <div className='card-header-title'>
                                    <span className='card-text'>{t('estimation.DPE.0.subText1')} <span className='card-text_lightblue'>{t('estimation.DPE.0.subText2')} </span>{t('estimation.DPE.0.subText3')} </span>
                                </div>

                                <EnergyContainer startLetter={saleForm.energyLevel} energyArray={propertyPricePerEnergyLabels} />

                            </div>
                            <div className='card-dpe'>
                                <div className='card-header-title'>
                                    <span className='card-text'>{t('estimation.DPE.1.subText1')} <span className='card-text_lightblue'>{t('estimation.DPE.1.subText2')} </span> {t('estimation.DPE.1.subText3')} </span>
                                </div>

                                <EnergyContainer energyArray={propertyPricePerEnergyLabels} clickable={true} />

                            </div>
                        </div>

                        <div className='estimation-middle-text'>
                            <div className="text">{t('estimation.footerText')} </div>
                        </div>
                         <EstimationArticle />
                    </div>
                </div>
            </>
        }
        { process.env.REACT_APP_SHOW_WIDGET === 'true' ? <ChatGPTWidget /> : <></> }
    </div>
}

export default EstimationCard;