import '../styles/footer.scss';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    return <footer>
        <div className="footer-list">
            <span><a href="https://www.sextantfrance.fr/fr/cgu.htm">{t("footer.0")}</a></span>
            <span><a href="https://www.sextantfrance.fr">{t("footer.1")}</a></span>
            <span><a href="https://www.ppd-rgpd.com/fr/75011/75011">{t("footer.2")}</a></span>
        </div>
    </footer>
}

export default Footer;