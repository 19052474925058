import { useEffect } from 'react';
import { Form } from '../interface/forms';
import "../styles/maps.scss";

interface StreetViewProps {
    form: Form;
}


const StreetView = (props: StreetViewProps) => {

    function initialize() {
        const fenway = { lat: props.form.estimatedPrice.latitude, lng: props.form.estimatedPrice.longitude };
        new google.maps.StreetViewPanorama(
            document.getElementById("pano") as HTMLElement,
            {
                position: fenway,
                pov: {
                    heading: 90,
                    pitch: 0,
                },
                zoom: 0,
                fullscreenControl: false,
                addressControl: false
            }
        );
    }

    useEffect(() => {
        initialize();
    })

    return <div className="maps-street-view">
        <div className="street-view-pano" id="pano"></div>
    </div>
}

export default StreetView;